<template>
    <div class="forgotPassPage">
            <h4>Forgot Password? Get It Here</h4>
            <p class="mt-2">Please enter your registered email address.</p>
            <p class="mt-1">You will receive a New Password in your mail.</p>
            <hr/>
            <form >
                <div class="form-group">
                    <label for="email_id">Email ID</label>
                    <input type="text" v-model="user.email_id" name="email_id" class="form-control" :class="{ 'is-invalid': submitted && errors.has('email_id') }" required/>
                    <div v-if="submitted && errors.has('email_id')" class="invalid-feedback">{{ errors.first('email_id') }}</div>
                    <div v-if="invalidEmail" class="col-12 alert alert-danger" >
                            {{emailMessage}}
                    </div>
                </div>
                
                <div class="form-group">
                    <div class="row">
                    <div class="col-6 text-right">
                        <button @click="handleSubmit()" class="btn btn-outline-success p-2 pl-4 pr-4">Submit</button>
                    </div>
                    <div class="col-6">
                    <!-- <img v-show="status.registering" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" /> -->
                    <b-button @click="cancel" variant="outline-warning" class=" p-2 pl-4 pr-4">Cancel</b-button>
                    </div>
                    </div>
                </div>
            </form>
        </div>
</template>

<script>
import { mapActions,mapGetters } from 'vuex'
export default {
     data () {
        return {
            submitted:false,
            invalidEmail: false,
            user:{
                email_id:'',
                url:''
            }
        }
     },
     methods:{
         ...mapActions({
            saveAnswer: 'loginmodule/saveAnswer'
        }),
        ...mapGetters({
            answerResponse: 'loginmodule/AnswerResponse'
        }),
         handleSubmit(){
             ////debugger
             var vm = this;
             vm.user.url = 'users/forgotPassword';
                ////console.log(this.user);
                vm.saveAnswer(this.user).then(()=>{
                        //console.log(this.answerResponse())
                        //vm.userAnswerResponse = this.answerResponse().data
                        //alert('Please check your email.');
                    });
                    vm.$alert(
                            "Please check your email/Spam folder for temporary new password.",
                            "Success",
                            "success",
                            {
                                confirmButtonText: "OK"
                            }).then(()=>{
                                vm.$parent.present='login'
                                vm.resetAll(); 
                            });
                    
         },
         resetAll() {
            this.user = {
                first_name:'',
                last_name:'',
                password:'',
                email_id:'',
                url:''
            }
            this.submitted=false,
            this.invalidEmail= false,
            this.passwordMatch= false,
            this.passwordMessage= '',
            this.confirmPassword= ''
        },
        emailvalidation: function (){
            this.invalidEmail = false
                    this.emailMessage = ''
                    var re = /[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}/igm
                    const email = this.user.email_id
                   // ////console.log(email)
                    if(!re.test(email))
                    {
                        this.emailMessage = 'Invalid email address.'
                        this.invalidEmail = true
                    }
        },
        passMatch: function () {
            this.passwordMatch = false
            this.passwordMessage = ''
            if(this.confirmPassword.trim() !== this.user.password)
            {
                this.passwordMessage = 'Password does not matched'
                this.passwordMatch = true
            }
        },
        cancel(){
            this.$parent.present='login'

        }
     },
     watch: {
         'user.email_id': {
            deep:true,
            handler ()  {
                if(this.user.email_id.trim() !== '')
                {
                    this.emailvalidation()
                    //his.allFilled =(this.invalidEmail | this.passwordMatch | this.invalidlicense)? false: true
                }
            }
        },
        confirmPassword: {
            handler() {
                if(this.confirmPassword.trim() !== '' & this.user.password.trim() !== '')
                {
                    this.passMatch()
                }
                //this.allFilled =(this.invalidEmail | this.passwordMatch | this.invalidlicense)? false: true
            }
        },
        'user.password': {
            handler() {
                if(this.confirmPassword.trim() !== '' & this.user.password.trim() !== '')
                {
                    this.passMatch()
                }
                //this.allFilled =(this.invalidEmail | this.passwordMatch | this.invalidlicense)? false: true
            }
        }
     }
}
</script>

<style scoped>
form{
    text-align: left;
}
form button{
    text-align: center;
}
.forgotpassword
{
    width: 40%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border: 5px solid #D9D9D9;
    padding: 20px;
    border-radius: 10px;
    background: #f7f7f7;
}

h1{
    font-size: 18px;
    font-weight: normal;
    color: #f60!important;
    font-family: Montserrat!important;
    /* font-family: 'Oswald',sans-serif; */
    padding: 0 0 7px 0;
    margin: 0;
    text-transform: uppercase;
}
</style>
