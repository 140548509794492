<template>
  <!--Footer Start-->
  <footer id="footer">
    <section class="footer-sectio-1">
      <div class="container">
        <div class="row">
          <div class="col-md-2">
            <div class="footer-box">
              <h4>QUICK LINKS</h4>
              <ul>
                <li><a href="https://blog.pteclasses.com/pte-speaking/" target="_blank">Speaking</a></li>
                <li><a href="https://blog.pteclasses.com/pte-writing/" target="_blank">Writing</a></li>
                <li><a href="https://blog.pteclasses.com/pte-reading/" target="_blank">Reading</a></li>
                <li><a href="https://blog.pteclasses.com/pte-listening/" target="_blank">Listening</a></li>
                <!-- <li><button class="buttonLink" @click="clickMenu('dropdown02')">Speaking </button></li>
                <li><button class="buttonLink" @click="clickMenu('dropdown03')">Writing</button></li>
                <li><button class="buttonLink" @click="clickMenu('dropdown04')">Reading</button></li>
                <li><button class="buttonLink" @click="clickMenu('dropdown05')">Listening</button></li> -->
              </ul>
            </div>
          </div>
          <div class="col-md-3">
            <div class="footer-box">
              <h4>CONNECT WITH US</h4>
              <ul>
                <li><a href="https://www.facebook.com/PTEandNAATIclasses" target="_blank">Facebook</a></li>
                <li><a href="https://www.instagram.com/pteclasses/" target="_blank">Instagram</a></li>
                <li><a href="https://twitter.com/PteClasses" target="_blank">Twitter</a></li>
                <li><a href="https://www.linkedin.com/company/pteclasses" target="_blank">Linkedin</a></li>
                <li><a href="https://www.youtube.com/channel/UCJrv5Ati1JbYzek6kB6cpTg" target="_blank">Youtube</a></li>
                <li><a href="mailto:info@pteclasses.com" target="_blank">Email: info@pteclasses.com </a></li>
                <li><p>Address: Level 3, 105 Queen St, Melbourne, VIC 3000</p></li>
              </ul>
            </div>
          </div>
          <div class="col-md-3">
            <div class="footer-box">
              <h4>RECENT BLOGS</h4>
              <ul>
                <li><a href="https://blog.pteclasses.com/pte-study-plan/" target="_blank">PTE Study Plan</a></li>
                <li><a href="https://blog.pteclasses.com/pte-vs-ielts-score/" target="_blank">PTE Vs IELTS Score Comparison</a></li>
                <li><a href="https://blog.pteclasses.com/pte-fill-in-the-blanks/" target="_blank">PTE Fill In The Blanks Tips, Tricks & Sample Practice Material</a></li>
                <li><a href="https://blog.pteclasses.com/pte-listening-guide/" target="_blank">Complete PTE Listening Guide</a></li>
                <li><a href="https://blog.pteclasses.com/pte-write-from-dictation/" target="_blank">PTE Write From Dictation Tips</a></li>
              </ul>
            </div>
          </div>
          <div class="col-md-2">
            <div class="footer-box">
              <h4>SERVICES</h4>
              <ul>
                <li><a href="https://thinkhigher.com.au/migration/" target="_blank">Migration</a></li>
                <li><a href="https://thinkhigher.com.au/education-services/" target="_blank">Education/Admissions</a></li>
                <li><a href="https://thinkhigher.com.au/professional-year/" target="_blank">Professional Year</a></li>
                <li><a href="https://thinkhigher.com.au/best-and-cheapest-health-insurance-for-all-types-of-visas/" target="_blank">Health Cover</a></li>
                <li><a href="https://thinkhigher.com.au/free-online-pte-classes-think-higher-consultants/" target="_blank">PTE Coaching</a></li>
                <li><a href="https://thinkhigher.com.au/naati/" target="_blank">NAATI Coaching</a></li>
              </ul>
            </div>
          </div>
          <div class="col-md-2">
            <div class="footer-box">
              <ul class="app-box">
                <li> <img src="@/assets/images/ios-scan-img.png" alt="img"> <strong class="title mb-5">iOS</strong> </li>
                <li> <img src="@/assets/images/android-scan-img.png" alt="img"> <strong class="title">Android</strong> </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="footer-sectio-2">
      <div class="container">
        <div class="holder">
          <div class="footer-social">
            <ul>
              <li><a href="https://www.facebook.com/PTEandNAATIclasses"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
              <li><a href="https://twitter.com/PteClasses "><i class="fa fa-twitter" aria-hidden="true"></i></a></li>
              <li><a href="https://www.linkedin.com/company/pteclasses"><i class="fa fa-linkedin" aria-hidden="true"></i></a></li>
              <li><a href="https://www.youtube.com/channel/UCJrv5Ati1JbYzek6kB6cpTg"><i class="fa fa-youtube-play" aria-hidden="true"></i></a></li>
            </ul>
          </div>
          <strong class="copy">&copy; Copyright 2020 Cyber Leaf Solutions. All rights reserved.</strong> </div>
      </div>
    </section>
  </footer>
  <!--Footer End-->
</template>
<script>
import $ from "jquery";
export default {
  data () {
    return {}
  },
  methods:{
    clickMenu(id){
      //console.log('#'+id)
      $('#'+id).click()
      $('#'+id +' ~ div a').click()
    }
  }
}
</script>
<style scoped>
.footer-box .app-box li
{
    width: 100%;
}
.footer-box li p{
  display: block;
color: #fff!important;
text-decoration: none;
/* font: 300 16px/30px 'Montserrat'; */
font: 300 16px/30px 'Montserrat'!important;
};

#footer button{ 
display: block;
color: #fff;
text-decoration: none;
/* font: 300 16px/30px Roboto,sans-serif; */
font: 300 16px/30px 'Montserrat'!important;
}
</style>
<style>
  .footer-box .buttonLink{ 
display: block;
color: #fff;
text-decoration: none;
/* font: 300 16px/30px Roboto,sans-serif; */
font: 300 16px/30px 'Montserrat'!important;
background: none !important;
border: 0px;
}
</style>