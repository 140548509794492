<template>
    <b-row>
    <div class="col-md-12 col-sm-12 fullwidth">
            <h2>{{HeadingText}}</h2>
            <hr/>
            <form @submit.prevent="handleSubmit">
                <div class="form-group">
                    <label for="first_name">First Name
                        <span style="color:red;"> *</span>
                    </label>
                    <!-- :disabled="HeadingText !='Sign Up'" -->
                    <input type="text" v-model="user.first_name" name="first_name" class="form-control" :class="{ 'is-invalid': submitted && errors.has('first_name') }" required/>
                    <div v-if="submitted && errors.has('first_name')" class="invalid-feedback">{{ errors.first('first_name') }}</div>
                </div>
                <div class="form-group">
                    <label for="last_name">Last Name
                        <span style="color:red;"> *</span>
                    </label>
                    <input type="text" v-model="user.last_name" name="last_name" class="form-control" :class="{ 'is-invalid': submitted && errors.has('last_name') }" required/>
                    <div v-if="submitted && errors.has('last_name')" class="invalid-feedback">{{ errors.first('last_name') }}</div>
                </div>
                <div class="form-group">
                    <label for="email_id">Email ID
                        <span style="color:red;"> *</span>
                    </label>
                    <input :disabled="HeadingText !='Sign Up'" type="text" v-model="user.email_id" name="email_id" class="form-control" :class="{ 'is-invalid': submitted && errors.has('email_id') }" required/>
                    <div v-if="submitted && errors.has('email_id')" class="invalid-feedback">{{ errors.first('email_id') }}</div>
                    <div v-if="invalidEmail" class="col-12 alert alert-danger" >
                            {{emailMessage}}
                    </div>
                </div>
                
                <div class="form-group">
                    <label htmlFor="password">Password
                         <span style="color:red;"> *</span>
                    </label>
                    <input type="password" v-model="user.password" name="password" class="form-control" :class="{ 'is-invalid': submitted && errors.has('password') }" required/>
                    <div v-if="submitted && errors.has('password')" class="invalid-feedback">{{ errors.first('password') }}</div>
                </div>
                <div class="form-group">
                    <label htmlFor="confirmPassword">Confirm Password
                        <span style="color:red;"> *</span>
                    </label>
                    <input type="password" v-model="confirmPassword" name="confirmPassword" class="form-control" :class="{ 'is-invalid': submitted && errors.has('confirmPassword') }" required/>
                    <div v-if="submitted && errors.has('confirmPassword')" class="invalid-feedback">{{ errors.first('confirmPassword') }}</div>
                    <div v-if="passwordMatch" class="col-12 alert alert-danger" >
                            {{passwordMessage}}
                    </div>
                </div>
                <div class="form-group" v-if="referenceUser!=null">
                    <label htmlFor="referralUser">Referral User</label>
                    <input disabled type="text" v-model="referenceUser" name="referenceUser" class="form-control" />
                    
                </div>
                <div class="form-group" v-if="userProfile==null">
                    <b-input-group>
                        <b-form-input disabled v-model="securePin"></b-form-input>
                        <b-input-group-append >
                        <b-button @click="sendSecurePin()" variant="outline-primary">Get Secure PIN</b-button>
                        </b-input-group-append>
                    </b-input-group>
                </div>
                <div class="form-group">
                    <div class="row">
                    <div class="col-lg-6 col-md-12 mt-2 text-right btnDivMobile">
                        <b-button type="submit" variant="outline-success" class="p-2 pl-4 pr-4">{{buttonText}}</b-button>
                    </div>
                    <div class="col-lg-6 col-md-12 mt-2 btnDivMobile">
                    <!-- <img v-show="status.registering" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" /> -->
                    <b-button v-if="userProfile!=null" @click="deleteAccount"  variant="outline-danger" class="p-2 pl-4 pr-4">Permanently Delete Account</b-button>
                    <b-button v-if="userProfile==null" @click="cancel"  variant="outline-warning" class="p-2 pl-4 pr-4">Cancel</b-button>
                    </div>
                    </div>
                    <!-- <router-link to="/" class="btn btn-link">Cancel</router-link> -->
                </div>
            </form>
        
        </div>
        <!-- <div class="col-12 mb-4" >
            
            <GoogleLogin :params="params"  :onSuccess="onSuccess" class="btn gmailBtn">
                <img src="../assets/images/google.png" class="googleIcon"> Sign up with Gmail
            </GoogleLogin> 
             </div>
             <div class="col-12 mb-4">
                 <facebook-login class="fb-signin-button"
                    appId="526833059617006"
                    @login="onLogin"
                    @logout="onLogout"
                    @sdk-loaded="sdkLoaded"
                    loginLabel="Sign up with Facebook">
                    </facebook-login>
             </div> -->
             <canvas id='textCanvas' height=40 width="40"></canvas>
             <b-modal ref="boltValidationModal" hide-footer 
             title="Verify you are not a Bolt">
                  <div class="row">
                      <div class="col-12 text-center">
                        <div class="mr-2 inlineBlock">
                            
                            <img id='image1'>
                            <!-- <h3><strong>{{radNumberOne}}</strong></h3> -->
                        </div>
                        <div class="ml-2 mr-3 inlineBlock">
                            <h3 v-if="radNumberOne>radNumberTwo"><strong>-</strong></h3>
                            <h3 v-else><strong>+</strong></h3>
                        </div>
                        <div class="inlineBlock">
                            <!-- <h3><strong>{{radNumberTwo}}</strong></h3> -->
                            <img id='image2'>
                        </div>
                        <div class="mr-2 inlineBlock">
                            <h3><strong>=</strong></h3>
                        </div>
                        <div class="validInput inlineBlock">
                            <input type="text" v-model="validateEqu">
                        </div>
                      </div>
                        <div class="mt-3 col-12 text-center">
                            <hr/>
                             <b-button class="mr-4 p-2 pl-4 pr-4" size="sm" variant="outline-success" @click="verify()">
                            Verify
                        </b-button>
                        <b-button class="p-2 pl-4 pr-4" size="sm" variant="outline-danger" @click="closeModal()">
                            Close
                        </b-button>
                        </div>
                    </div>
             </b-modal>
        </b-row>
</template>

 <script>
import { mapActions,mapGetters } from 'vuex'
import GoogleLogin from 'vue-google-login';
import facebookLogin from 'facebook-login-vuejs';
import $ from 'jquery'
export default {
    props:['userProfile'],
     data () {
        return {
            submitted:false,
            invalidEmail: false,
            passwordMatch: false,
            passwordMessage: '',
            confirmPassword: '',
            buttonText:'Sign Up',
            HeadingText:'Sign Up',
            securePin:'',
            verfiyPin:'',
            radNumberOne:0,
            radNumberTwo:0,
            validateEqu:'',
            params: {
                    client_id: "662951235152-ua8fih2usk2g9h2bfh81i8d2dvstt0vl.apps.googleusercontent.com"
                },
            renderParams: {
                    width: 250,
                    height: 50,
                    longtitle: true
                },
            user:{
                first_name:'',
                last_name:'',
                password:'',
                email_id:'',
                active_ind:'T',
                url:'',
                reference_user_id:null
            },
            fb: {
                cu:'',
                fV:'',
                isConnected:false
            },
            referralId:null,
            referenceUser:null,
        }
     },
     components:{
            GoogleLogin,
            facebookLogin,
        },
     beforeMount(){
         this.securePin=''
         this.referenceUser=null;
        if(this.userProfile!=null){
            this.HeadingText = 'User Information'
            this.buttonText = 'Update User'
            this.user= this.$ls.get('user')
        }
        if(this.$ls.get('referral')!=null){
            debugger;
            const value=JSON.parse(this.$ls.get('referral'))
            this.referralId=value //.encryptValue
            console.log(this.referralId)
            this.fetchUserMiniInfo('/users/getUserNameById/id='+this.referralId).then(()=>{
            var response = this.getUserPersonalinfo();
           if(response==null | response.success!=1){
            this.$alert(
                            "Invalid reference link, you will be registered without referral",
                            "Warning",
                            "warning",
                            {
                                confirmButtonText: "Ok"
                            })
           }
           else{
            this.referenceUser=response.data.first_name +" "+response.data.last_name;
           }
            //this.personalInfo=response.data
            console.log(response.data)
          });
        }
        //this.siteKey="6LfTNNEbAAAAAINID99Eaa9YGIhJcLaxW3D1e08B"//process.env.VUE_APP_RECAP_KEY
     },
     methods:{
         ...mapActions({
            saveAnswer: 'loginmodule/saveAnswer',
            updateUser: 'auth/updateUserType',
            saveData:'auth/saveDashBoardData',
            loginSocialMedia: 'auth/loginSocialMedia',
            saveUserRefData: 'speakmodule/saveAnswer',
            fetchUserMiniInfo:'auth/fetchUserMiniInfo',
        }),
        ...mapGetters({
            answerResponse: 'loginmodule/AnswerResponse',
            getData:'auth/getDashBoardResponse',
            authDetail: 'auth/authenticated',
            userRefResponse: 'speakmodule/AnswerResponse',
            getUserPersonalinfo: 'auth/getPersonalInfo',
        }),
        convertTextToImg(imageElem, value){
            var font = '400 30px "Fredoka One script=all rev=2", "Adobe Blank"';
            var tCtx = document.getElementById('textCanvas').getContext('2d')
            tCtx.canvas.width = tCtx.measureText(value).width+20;
            tCtx.font = font;
            // set the color only now
            tCtx.fillStyle = '#000';
            tCtx.fillText(value, 0, 30);
            imageElem.src = tCtx.canvas.toDataURL()
        },
        async handleClickSignIn() {
            debugger
      try {
        const googleUser = await this.$gAuth.signIn();
        if (!googleUser) {
          return null;
        }
        console.log("googleUser", googleUser);
        console.log("getId", googleUser.getId());
        console.log("getBasicProfile", googleUser.getBasicProfile());
        console.log("getAuthResponse", googleUser.getAuthResponse());
        console.log(
          "getAuthResponse",
          this.$gAuth.GoogleAuth.currentUser.get().getAuthResponse()
        );
        this.isSignIn = this.$gAuth.isAuthorized;
      } catch (error) {
        //on fail do something
        console.log(error);
        return null;
      }
    },
    deleteAccount(){
        debugger;
        if(this.user.user_type=='Free')
             {
                this.$confirm("Are you sure you want to remove your account permanently?",
                              "Confirmation",
                              "question",
                              {
                                confirmButtonText: "Yes"
                              }).then(()=>{
                                        this.user.url='users/deleteuser';
                                        this.updateUser(this.user).then(()=>{
                                            this.userAnswerResponse = this.answerResponse();
                                            this.$ls.remove('tokenVal')
                                                        this.$ls.remove('uniqueKey')
                                                        this.$ls.remove('userId');
                                                        this.$ls.remove('user')
                                                this.$alert(
                                                    "You account is permanently removed from our storage.",
                                                    "Success",
                                                    "success",
                                                    {
                                                        confirmButtonText: "Account Deleted"
                                                    }
                                                    ).then(()=>{
                                                        
                                                         window.open('https://pteclasses.com','_self');
                                                    })
                                    
                                     
                                  })
                              }).catch(() => {
                                return //console.log('cancel')
                              });
            
                
        }
        else{
            this.$alert(
                "You need to change your subscription to Free user before removing your account.",
                "Warning",
                "warning",
                {
                    confirmButtonText: "OK"
                }
                );
        }
    },
        showBoldValidation(){
            this.radNumberOne=Math.floor((Math.random() * 100) + 1)
            this.radNumberTwo=Math.floor((Math.random() * 100) + 1)
            if(this.radNumberOne==this.radNumberTwo) this.radNumberTwo=Math.floor((Math.random() * 100) + 1)
            this.validateEqu=''
            this.$refs['boltValidationModal'].show()
            this.$nextTick(()=>{
                var imageElem1 = document.getElementById('image1');
                this.convertTextToImg(imageElem1,this.radNumberOne)
                var imageElem2 = document.getElementById('image2');
                this.convertTextToImg(imageElem2,this.radNumberTwo)
            })
            
        },
        closeModal(){
            this.$refs['boltValidationModal'].hide()
        },
        verify(){
            if(this.radNumberOne>this.radNumberTwo){
                if( this.validateEqu==(this.radNumberOne-this.radNumberTwo))
                {
                    this.closeModal()
                    this.securePin=this.verfiyPin
                }
                else {
                    this.closeModal()
                    this.resetAll()
                    this.$alert(
                            "You provided a wrong answer",
                            "Failed",
                            "error",
                            {
                                confirmButtonText: "Retry"
                            })
                }
            }
            else{
                if( this.validateEqu==(this.radNumberOne+this.radNumberTwo))
                {
                    this.closeModal()
                    this.securePin=this.verfiyPin
                }
                else 
                {
                    this.closeModal()
                    this.resetAll()
                    this.$alert(
                            "You provided a wrong answer",
                            "Failed",
                            "error",
                            {
                                confirmButtonText: "Retry"
                            })
                }
            }
        },
        saveUserReferal(insertedId){
            /// not in use as I am saving it in controller
            var refData={
                url:'users/createUserReferal',
                reference_user_id:this.referralId,
                register_user_id:insertedId
            }
            this.saveUserReferal(refData).then(()=>{
                const resp=this.userRefResponse()
                //console.log(resp)
            })
        },
        onSuccess(googleUser) {
            ////console.log(googleUser);
 
            // This only gets the user information: id, name, imageUrl and email
            //console.log(googleUser.getBasicProfile());
            const tempData=googleUser.getBasicProfile();
            ////console.log(tempData)
            //console.log(tempData.getEmail())
            
            var user = {
                        first_name: tempData.getGivenName(),
                        email_id: tempData.getEmail(),
                        last_name:tempData.getFamilyName(),
                        active_ind:'T',
                        reference_user_id:this.referralId
                    };
            
            if(user.first_name== null){
                return this.$alert(
                            "Login Failed.",
                            "Failed",
                            "error",
                            {
                                confirmButtonText: "Try Again!"
                            });
            }
            //console.log(user);
            //this.facebookPixelIntegration(user.first_name,user.email_id,"000000");
            this.loginSocialMedia(user).then(()=>{
            const details = this.authDetail()
            debugger;
            //console.log(this.$ls.get('NewUser'))
            this.successfullyRegisteredMsg(this.$ls.get('NewUser'));
            // this.$ls.set('tokenVal',details.token)
           //  this.$ls.set('user',details.user)
           // this.$ls.set('currentQuestion','')
            //this.$parent.callingRecheckUser()//resetUser()
            //LocalStorageService.setCurrentQuestion('')
            
            //////console.log(this.currentUser)
            //this.$root.$emit('bv::hide::modal', 'login-Modal')
            });
            //this.googleLogin(googleUser.getBasicProfile());
            
        },
        successfullyRegisteredMsg(statusCode){
            //console.log(this.$ls.get('NewUser'))
var vm=this;
            if(statusCode=='2'){
                vm.$alert(
                            "Sign up Failed, Email Id already registered, Login with this email ID.",
                            "Warning",
                            "warning",
                            {
                                confirmButtonText: "Ok"
                            }
                            );
                            this.$parent.present='login'
            }
            else if(statusCode=='1'){
                vm.$alert(
                            "Sign up successfully, You can login with your registered credentials.",
                            "Success",
                            "success",
                            {
                                confirmButtonText: "OK"
                            }
                            )
                            this.$parent.present='login'
            }
            else{
                vm.$alert(
                            "Sign up Failed.",
                            "Failed",
                            "error",
                            {
                                confirmButtonText: "Try Again!"
                            }
                            );
            }
        },
        sdkLoaded(payload){
            this.fb.isConnected = payload.isConnected;
            this.fb.FB = payload.FB
            if(this.isConnected) this.getUserData();
        },
        onLogin(){
            debugger
            this.fb.isConnected = true;
           
            this.getUserData();
        },
        onLogout(){
            this.isConnected = false;
        },
        getUserData(){
            window.FB.api('/me','GET',{fields: 'name,email'},
                userInformation =>{
                    //console.warn("data api", userInformation);
                    this.fb.cu = userInformation.email;
                    this.fb.fV = userInformation.name;
                    debugger
                    //console.log(this.fb)
                    if(this.fb.cu!=null){
                        var user = {
                        first_name: this.fb.fV,
                        email_id: this.fb.cu,
                        active_ind:'T',
                        reference_user_id:this.referralId
                            };
                           // this.facebookPixelIntegration(user.first_name,user.email_id,"000000");
                        this.loginSocialMedia(user).then(()=>{
                        const details = this.authDetail()
                        this.successfullyRegisteredMsg(this.$ls.get('NewUser'));
                        //console.log(details)
                        //this.$ls.set('tokenVal',details.token)
            // this.$ls.set('user',details.user)
           // this.$ls.set('currentQuestion','')
          //  this.$parent.callingRecheckUser()
                      //  this.$ls.remove('currentQuestion');
                      //  this.$parent.callingRecheckUser()//resetUser()
                        //LocalStorageService.setCurrentQuestion('')
                        
                        //////console.log(this.currentUser)
                       // this.$root.$emit('bv::hide::modal', 'login-Modal')
                        });
                    }
                    
                }
            )

            
        },
        facebookPixelIntegration(email,name,phone){
            let data={
                name:name,
                email:email,
                phone:phone,
                url:'users/facebookPixelLink'
            };
            this.saveAnswer(data).then(()=>{
                        //console.log(this.answerResponse().success)
            });
        },
        sendSecurePin(){
            this.verfiyPin=''
            this.securePin=''
            this.emailvalidation()
            
            if(this.user.first_name=='' | this.user.last_name=='' | this.user.password==''){
                return this.$alert(
                            "Please fill all required fields",
                            "Warning",
                            "warning",
                            {
                                confirmButtonText: "Close"
                            })
            }
            else if(this.confirmPassword !== this.user.password){
                return this.$alert(
                            "Please first confirm your password",
                            "Warning",
                            "warning",
                            {
                                confirmButtonText: "Close"
                            })
            }
            else if(this.invalidEmail)
            {
                return this.$alert(
                            "Invalid Email Address",
                            "Failed",
                            "error",
                            {
                                confirmButtonText: "Try again"
                            })
            }
            const data={
                    email_id:this.user.email_id,
                    url:'users/insertVerifyKey',
                    temp_key:''
                }
                var vm=this
                this.saveData(data).then(()=>{
                    if(vm.getData().success!=1) return alert('Issue on PIN generation, try again.')
                    //console.log(vm.getData().key)
                    vm.verfiyPin=vm.getData().key
                });
            return this.showBoldValidation()
            //LOGIC CHANGE AFTER DISCUSSION WITH ANEEL BHAI
            // this.emailvalidation()
            // if(this.invalidEmail)
            // {
            //     this.$alert(
            //                 "Invalid Email Address",
            //                 "Failed",
            //                 "error",
            //                 {
            //                     confirmButtonText: "Try again"
            //                 })
            // }
            // else{
            //     const data={
            //         email_id:this.user.email_id,
            //         url:'users/insertVerifyKey',
            //         temp_key:''
            //     }
            //     var vm=this
            //     this.saveData(data).then(()=>{
            //         if(vm.getData().success!=1) return alert('Issue on PIN generation, try again.')
            //         //console.log(vm.getData().key)
            //         vm.verfiyPin=vm.getData().key
            //         vm.$alert(
            //             "A new eight digit pin is send to your email, Do check your spam folder if it doesn't arrive in your inbox, Please enter the pin to proceed.",
            //             "Success",
            //             "success",
            //             {
            //                 confirmButtonText: "OK"
            //             })
            //     });
            // }
        },
        handleSubmit(){
             var vm = this;
             ////debugger
             if(this.userProfile==null)
             {
                 if(this.verfiyPin!=this.securePin | this.verfiyPin=="")
                 {
                     return vm.$alert(
                            "Please enter correct PIN to proceed.",
                            "Invalid PIN",
                            "error",
                            {
                                confirmButtonText: "Enter Again!"
                            }
                            );
                 }
                 else if(this.confirmPassword !== this.user.password){
                    return this.$alert(
                                "Please first confirm your password",
                                "Warning",
                                "warning",
                                {
                                    confirmButtonText: "Close"
                                })
                     }
                 vm.user.url = 'users/createuser';
                 vm.user.reference_user_id=this.referralId
                ////console.log(this.user);
                vm.saveAnswer(this.user).then(()=>{
                        //console.log(this.answerResponse().success)
                        vm.userAnswerResponse = this.answerResponse();
                        if(this.answerResponse().success == '1'){
                            vm.$alert(
                            "Sign up successfully, You can login with your registered credentials.",
                            "Success",
                            "success",
                            {
                                confirmButtonText: "OK"
                            }
                            )
                            //vm.facebookPixelIntegration(vm.user.first_name,vm.user.email_id,"000000");
                            this.$parent.present='login'
                        }else if(this.answerResponse().success == '2'){
                            vm.$alert(
                            "Sign up Failed, Email Id already registered",
                            "Failed",
                            "error",
                            {
                                confirmButtonText: "Try Again!"
                            }
                            );
                        }
                        else{
                            vm.$alert(
                            "Sign up Failed.",
                            "Failed",
                            "error",
                            {
                                confirmButtonText: "Try Again!"
                            }
                            );
                        }
                        
                        //vm.resetAll();
                    });
             
             }
             else{
                 vm.user.url = 'users/userupdate';
                ////console.log(this.user);
                vm.updateUser(this.user).then(()=>{
                    vm.$ls.set('user',vm.user)
                        ////console.log(this.answerResponse())
                        vm.userAnswerResponse = this.answerResponse()
                         vm.$alert(
                            "Information is updated successfully",
                            "Success",
                            "success",
                            {
                                confirmButtonText: "All Good!"
                            }
                            )
                        //alert('Sign up successfull.');
                        //this.$parent.present='login'
                        //vm.resetAll();
                    });
             }
             
         },
         resetAll() {
            this.user = {
                first_name:'',
                last_name:'',
                password:'',
                email_id:'',
                url:''
            }
            this.submitted=false,
            this.invalidEmail= false,
            this.passwordMatch= false,
            this.passwordMessage= '',
            this.confirmPassword= ''
        },
        emailvalidation: function (){
            this.verfiyPin=''
            this.invalidEmail = false
                    this.emailMessage = ''
                    var re = /[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}/igm
                    const email = this.user.email_id
                   // ////console.log(email)
                    if(!re.test(email))
                    {
                        this.emailMessage = 'Invalid email address.'
                        this.invalidEmail = true
                    }
        },
        passMatch: function () {
            this.passwordMatch = false
            this.passwordMessage = ''
            if(this.confirmPassword !== this.user.password)
            {
                this.passwordMessage = 'Password does not matched'
                this.passwordMatch = true
            }
        },
        cancel(){
            this.$parent.present='login'

        }
     },
     watch: {
         'user.email_id': {
            deep:true,
            handler ()  {
                if(this.user.email_id !=null)
                if(this.user.email_id.trim() !== '')
                {
                    this.emailvalidation()
                    //his.allFilled =(this.invalidEmail | this.passwordMatch | this.invalidlicense)? false: true
                }
            }
        },
        confirmPassword: {
            handler() {
                if(this.confirmPassword!=null)
                if(this.confirmPassword !== '' & this.user.password !== '')
                {
                    this.passMatch()
                }
                //this.allFilled =(this.invalidEmail | this.passwordMatch | this.invalidlicense)? false: true
            }
        },
        'user.password': {
            handler() {
                if(this.confirmPassword!=null)
                if(this.confirmPassword !== '' & this.user.password !== '')
                {
                    this.passMatch()
                }
                //this.allFilled =(this.invalidEmail | this.passwordMatch | this.invalidlicense)? false: true
            }
        }
     },

}
</script>

<style scoped>
form{
    text-align: left;
}
form button{
    text-align: center;
}
canvas {
  display: none;
}
.inlineBlock{
    display: inline-block;
}
.validInput input{
    width: 80px;
    font-size: 20px;
    text-align: center;
    font-weight: 600;
}
.registerPage
{
    width: 40%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border: 5px solid #D9D9D9;
    padding: 20px;
    border-radius: 10px;
    background: #f7f7f7;
}
.googleIcon{
    width: 25px;
    left: 10px;
    position: absolute;
}
.btn.gmailBtn{
    background: #fff;
    padding: 8px 0px;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px!important;
    color: #757575!important;
}
</style>
