<template>
    <div class="fullShow">
        <a  class="loginlogo" href="https://pteclasses.com/"><img src="@/assets/images/newLogo1.png" style="width: 230px;" alt="img"></a>
        <div class="transbox"></div>
        <!-- <b-container class="bv-example-row bv-example-row-flex-cols"> -->
        <b-row align-v="center" class="maxHeight">
            <b-col class="loginBg text-center p-4 col-sm-12 col-md-6 col-lg-5 col-xl-4 ml-xl-5 ml-lg-5 m-md-auto">
                <Login :parentPresent="parentPresent"/>
            </b-col>
            <b-col class="d-none d-sm-none d-md-none d-lg-block col-lg-6 d-xl-block col-xl-6">
                <!-- <div class="sideImage"></div> -->
                <img class="loginBanner" src="../../assets/images/banner.png"/>
                
            </b-col>
            
                
        </b-row>
        <div class="policyConditionDiv">
                    <a href="https://pteclasses.com/privacypolicy/" target="_blank">Privacy Policy</a>
                    <a href="https://pteclasses.com/terms-conditions/" target="_blank">Terms & Conditions</a>
                </div>
        <!-- </b-container> -->
    </div>
</template>
<script>
import Login from '../login'
export default {
    props:{
        parentPresent:{
            default:'login',
            required:false
        }
    },
    components:{
        Login
    }
}
</script>
<style scoped>
.fullShow{
    background-color: #fff;
}
.sideImage { 
  background: url("../../assets/images/vectorbg.png") no-repeat center center fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100vh;
}
div.transbox {
  /* background-color: #2d2626;
    opacity: .5; */
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
}
.loginBg{
    background-color: #fff;
    border-radius: 5px;
    margin-top: 100px!important;
}
.loginlogo
{
    position: absolute;
    top: 10px;
    left: 75px;
}
.maxHeight
{
    min-height: 100vh;
}
.loginPage
{
    border: 5px solid #1e467f;
    padding: 20px!important;
    border-radius: 10px;
    background: #f4f6fc;
}

.loginBanner
{
    display: block;
    width: 100%;
    padding: 20px;
}
</style>
<style>
#footer
{
    display: none!important;
}
.loginPage h2, .loginPage{
color: #1e467f!important;
}

.loginPage hr{
    border-top: 4px solid #1e467f!important;
    /* margin-left: -20px;
    margin-right: -20px; */
}
.policyConditionDiv{
    width: 100%;
    text-align: center;
    position: fixed;
    bottom: 5px;
}
.policyConditionDiv a{
    color: #000;
    padding-right: 20px;
    text-decoration: underline;
    font-weight: 500;
}
</style>