<template>
    <div class="loginPage resetPassPage col-12 pl-0 pr-0">

            <h2 class="mb-4">RESET PASSWORD</h2>
            <form @submit.prevent="handleSubmit">
                <div class="form-group">
                    <label htmlFor="password">New Password</label>
                    <input type="password" v-model="user.password" name="password" class="form-control" :class="{ 'is-invalid': submitted && errors.has('password') }" required/>
                    <div v-if="submitted && errors.has('password')" class="invalid-feedback">{{ errors.first('password') }}</div>
                </div>
                <div class="form-group">
                    <label htmlFor="confirmPassword">Confirm Password</label>
                    <input type="password" v-model="confirmPassword" name="confirmPassword" class="form-control" :class="{ 'is-invalid': submitted && errors.has('confirmPassword') }" required/>
                    <div v-if="submitted && errors.has('confirmPassword')" class="invalid-feedback">{{ errors.first('confirmPassword') }}</div>
                    <div v-if="passwordMatch" class="col-12 alert alert-danger" >
                            {{passwordMessage}}
                    </div>
                </div>
                <div class="form-group ">
                    <button type="submit" class="btn btn-primary mt-2">Update Password</button>
                </div>
            </form>
        </div>
</template>

<script>
import { mapActions,mapGetters } from 'vuex';
//import LocalStorageService from '../store/LocalStorageService'
export default {
     data () {
        return {
            submitted:false,
            passwordMatch: false,
            passwordMessage: '',
            confirmPassword: '',
            user:{
                password:'',
                user_id:'',
                url:''
            }
        }
     },
     methods:{
         ...mapActions({
            updatePassword: 'loginmodule/updatePassword'
        }),
        ...mapGetters({
            answerResponse: 'loginmodule/AnswerResponse'
        }),
         handleSubmit(){
            //var token = this.$route.params.token;
             
             this.user.user_id = this.$ls.get('userId')
             //token = token.split('=')[1];
             //this.$ls.set('tokenVal',token)
             //////console.log(token);
             //LocalStorageService.setResetToken(token);
             //console.log(this.$ls.get('tokenVal'))
             var vm = this;
             vm.user.url = 'users/resetPassword';
                ////console.log(this.user);
                vm.updatePassword(this.user).then(()=>{
                        ////console.log(this.answerResponse())
                        this.$ls.remove('tokenVal')
                        this.$ls.remove('uniqueKey')
                        this.$ls.remove('userId')
                        vm.userAnswerResponse = this.answerResponse()
                        vm.$root.$emit('bv::hide::modal', 'login-Modal')
                        vm.$alert(
                            vm.userAnswerResponse,
                            "Success",
                            "success",
                            {
                                confirmButtonText: "OK"
                            }).then(()=>{
                                window.location.reload()
                            })
                        //alert('Password updated successfull.');
                        vm.resetAll();
                        
                        
                    });
         },
         resetAll() {
            this.user = {
                password:'',
                email_id:'',
                url:''
            }
            this.submitted=false,
            this.passwordMatch= false,
            this.passwordMessage= '',
            this.confirmPassword= ''
        },
        passMatch: function () {
            this.passwordMatch = false
            this.passwordMessage = ''
            if(this.confirmPassword.trim() !== this.user.password)
            {
                this.passwordMessage = 'Password does not matched'
                this.passwordMatch = true
            }
        },
     },
     watch: {
        confirmPassword: {
            handler() {
                if(this.user.password!=null)
                if(this.confirmPassword.trim() !== '' & this.user.password.trim() !== '')
                {
                    this.passMatch()
                }
                //this.allFilled =(this.invalidEmail | this.passwordMatch | this.invalidlicense)? false: true
            }
        },
        'user.password': {
            handler() {
                if(this.confirmPassword.trim() !== '' & this.user.password.trim() !== '')
                {
                    this.passMatch()
                }
                //this.allFilled =(this.invalidEmail | this.passwordMatch | this.invalidlicense)? false: true
            }
        }
     }
}
</script>

<style>
.resetPage
{
    width: 40%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border: 5px solid #D9D9D9;
    padding: 20px;
    border-radius: 10px;
    background: #f7f7f7;
}
.loginPage label
{
font-weight: 600;
text-align: left;
display: block !important;
padding-left: 4px;
}
</style>
