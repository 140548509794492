<template>
    <div>
    </div>
</template>
<script>
export default {
    beforeMount(){
        var token = this.$route.params.token;
        ////debugger
        //console.log(token)
        var user_id = token.split('=')[0].replace(':token-',"")
        token = token.split('=')[1];
        this.$ls.set('tokenVal',token)
        this.$ls.set('tokenVal',token)
        this.$ls.set('userId',user_id)
        this.$ls.set('redirect','EmailVerification')
        this.$router.push('/')
    }
}
</script>